<template>
    <pull-to :top-load-method="fetchPayments">
        <template slot="top-block" slot-scope="props">
            <div class="top-load-wrapper text-center justify-content-center">
                <v-icon
                    :size="props.diff < 55 ? props.diff : 55"
                    :color="props.state === 'trigger' || props.state === 'loading' ? 'primary' : 'grey'"
                >
                    mdi-arrow-down-thin
                </v-icon>
            </div>
        </template>
        <v-card flat>
            <v-row no-gutters class="mt-5">
                <v-col cols="12" class="d-flex justify-start align-center">
                    <v-btn x-large icon @click="$router.push('/user')">
                        <v-icon color="#343434">mdi-chevron-left</v-icon>
                    </v-btn>
                    <span class="h6 mb-0">Meine Zahlungen</span>
                </v-col>
            </v-row>
            <v-row class="justify-content-center" no-gutters>
                <v-col cols="auto" class="text-center">
                    <v-row no-gutters v-if="paymentsLoaded">
                        <v-col :cols="isSeb ? 11 : 12">
                            <v-card-subtitle class="pb-0 px-0">Badge Guthaben</v-card-subtitle>
                            <v-card-title class="h2 pt-1 px-0">{{ formattedBalance }}</v-card-title>
                        </v-col>
                        <v-col v-if="isSeb" cols="1" class="align-content-center">
                            <v-btn icon @click="addBalanceDialog = true" class="primary lighten-1">
                                <v-icon color="white">mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-else>
                        <v-col :cols="isSeb ? 11 : 12">
                            <v-card-subtitle style="text-wrap: nowrap" class="pb-0 px-0">
                                Badge Guthaben
                            </v-card-subtitle>
                            <v-card-title class="h2 pt-1 px-0 justify-content-center">
                                <v-progress-circular size="38" indeterminate color="primary"></v-progress-circular>
                            </v-card-title>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-switch
                    class="ml-3"
                    v-model="showAllPayments"
                    :loading="!paymentsLoaded"
                    :label="showAllPayments ? 'Zahlungen' : 'offene Rechnungen'"
                ></v-switch>
                <v-alert
                    class="ma-auto"
                    v-if="(!transactionsMapped || !transactionsMapped.length) && paymentsLoaded"
                    type="info"
                >
                    {{ showAllPayments ? 'Keine Zahlungen gefunden' : 'Keine offenen Rechnungen' }}
                </v-alert>
                <v-col v-if="!paymentsLoaded" class="my-2 px-1" cols="12">
                    <v-card class="rounded-xl">
                        <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
                    </v-card>
                </v-col>
                <v-col v-if="!paymentsLoaded" class="my-2 px-1" cols="12">
                    <v-card class="rounded-xl">
                        <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
                    </v-card>
                </v-col>
                <v-col
                    class="my-2 px-1"
                    cols="12"
                    v-for="(payment, i) of transactionsMapped"
                    :key="i + '-paymentIndex'"
                >
                    <v-card class="pa-3 rounded-xl">
                        <v-row no-gutters>
                            <v-col cols="2" class="align-content-center">
                                <v-img
                                    :src="
                                        getImgForTransactions(
                                            payment.type === 'Mitgliedschaft' ? payment.type : payment.Type
                                        )
                                    "
                                ></v-img>
                            </v-col>
                            <v-col cols="10">
                                <div class="ml-3">
                                    <v-chip
                                        class="mr-1"
                                        small
                                        v-if="
                                            !(
                                                payment.Type === 'OPEN_PAYMENT' &&
                                                payment.MainType === 'RESULTING_PAYMENTS'
                                            )
                                        "
                                    >
                                        {{
                                            payment.AccountTransactionDetails
                                                ? $moment(payment.TransactionDate).format('DD MMM YYYY')
                                                : $moment(payment.TransactionDate)
                                                      .subtract(2, 'hours')
                                                      .format('DD MMM YYYY HH:mm')
                                        }}
                                    </v-chip>
                                    <v-chip
                                        v-if="payment.AccountTransactionDetails"
                                        class="white--text mr-1"
                                        small
                                        :color="getChipTypeColor(payment)"
                                    >
                                        {{ getChipTypeText(payment) }}
                                    </v-chip>
                                </div>
                                <v-card-title
                                    class="h6 pt-1 pb-2 font-weight-bold"
                                    v-if="payment.AccountTransactionDetails"
                                >
                                    {{
                                        getMembershipPaymentDescription(
                                            payment.AccountTransactionDetails.AccountTransactionDetail[0].Description
                                        )
                                    }}
                                </v-card-title>
                                <v-card-title class="h6 pt-1 pb-2 font-weight-bold" v-else>
                                    {{ getPaymentDescription(payment.Description) }}
                                </v-card-title>
                                <v-card-subtitle class="pb-0">
                                    <!--

                                  Type: {{ payment.Type }}
                                  <br />
                                  mainType: {{ payment.MainType }}
                                  <br />
                                  type: {{ payment.type }}
                                  <br />
                                  
                                  details: {{ payment.AccountTransactionDetails.AccountTransactionDetail }}
                                -->
                                    <span
                                        v-if="
                                            payment.AccountTransactionDetails &&
                                            payment.AccountTransactionDetails.AccountTransactionDetail[0].TimeRange
                                        "
                                    >
                                        {{
                                            'Laufzeit: ' +
                                            $moment(
                                                payment.AccountTransactionDetails.AccountTransactionDetail[0].TimeRange
                                                    .Start,
                                                'YYYY-MM-DD'
                                            ).format('DD.MM.YYYY') +
                                            ' - ' +
                                            $moment(
                                                payment.AccountTransactionDetails.AccountTransactionDetail[0].TimeRange
                                                    .End,
                                                'YYYY-MM-DD'
                                            ).format('DD.MM.YYYY')
                                        }}
                                        <br />
                                    </span>
                                    <span
                                        v-if="payment.Value"
                                        :class="{
                                            'red--text': payment.Value.value < 0 || payment.Type === 'OPEN_PAYMENT',
                                            'green--text': payment.Value.value > 0 && !payment.Type === 'OPEN_PAYMENT',
                                        }"
                                    >
                                        {{ payment.Value.value + ' ' + payment.Value.currencyCode }}
                                    </span>
                                </v-card-subtitle>
                                <v-btn
                                    v-if="
                                        (isSeb &&
                                            payment.Type === 'OPEN_PAYMENT' &&
                                            payment.MainType === 'RESULTING_PAYMENTS') ||
                                        (payment.State === 'OPEN' && isSeb)
                                    "
                                    @click="initPaymentForTransaction(payment)"
                                    x-small
                                    absolute
                                    style="bottom: 0; right: 0"
                                    text
                                >
                                    JETZT ZAHLEN
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

            <v-dialog @click:outside="paymentInitiated = false" v-model="paymentInitiated" persistent>
                <payment-dialog
                    @completed="adyenPaymentCompleted"
                    :amount="addBadgeAmount"
                    :transactionRef="transactionRef"
                    v-if="paymentInitiated"
                ></payment-dialog>
            </v-dialog>
            <v-dialog content-class="rounded-xl" max-width="300" v-model="addBalanceDialog">
                <v-card class="rounded-xl">
                    <v-card-title class="primary white--text text-center justify-content-center">
                        Badge Aufladen
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                            class="mt-2"
                            filled
                            hide-details
                            solo
                            rounded
                            label="Menge"
                            suffix="CHF"
                            v-model="addBadgeAmount"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions class="justify-content-center pt-0">
                        <v-btn color="primary" @click="startPayment">
                            Zahlen
                            <v-icon>mdi-credit-card</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </pull-to>
</template>


<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import userService from '@/services/userService'
import api from '@/api'
import PaymentDialog from '../../payment/paymentDialog.vue'
import { formatCurrency } from '@/moduls/currency/currency.js'
import PullTo from 'vue-pull-to'

export default {
    name: 'UserPayments',
    components: {
        PaymentDialog,
        PullTo,
    },
    data() {
        return {
            paymentsLoaded: false,
            isSeb: false,
            paymentInitiated: false,
            addBalanceDialog: false,
            payments: {},
            userService,
            transactionRef: null,
            showAllPayments: false,
            statusMapper: {
                init: 'Offen',
                initialized: 'Offen',
                authenticated: 'Authentifiziert',
                authorized: 'Autorisiert',
                settled: 'Abgerechnet',
                canceled: 'Abgebrochen',
                transmitted: 'Übermittelt',
                failed: 'Fehlgeschlagen',
                created: 'Offen',
                deprecated: 'Abgebrochen',
            },
            paymentModel: 0,
            addBadgeAmount: 0,
        }
    },
    async created() {
        if (this.userService.getEmail() === 'sebs@wellcomefit.ch') this.isSeb = true
        await this.fetchPayments()
    },
    methods: {
        async fetchPayments(state = null) {
            this.paymentsLoaded = false
            this.payments = {}
            console.log('es passiert was')
            if (this.isSeb) {
                this.payments = await api.fetchAccountTransactionsForUserTESTING()
            } else {
                this.payments = await api.fetchAccountTransactionsForUser()
            }
            if (this.payments.lixt) {
                this.$toast.open({
                    type: 'error',
                    message:
                        'Dein Mahnstatus ist als "In Betreibung" markiert. Du wurdest über dieses Vorgehen informiert und unser Inkasso-Unternehmen Lixt wird oder hat bereits Kontakt mit dir aufgenommen.',
                    duration: 10000,
                })
                return this.$router.push('/user')
            }
            if (state) state('done')
            this.paymentsLoaded = true
        },
        async adyenPaymentCompleted(response) {
            this.paymentInitiated = false
            this.amount = null
            this.transactionRef = null
            await this.fetchPayments()
        },
        initPaymentForTransaction(payment) {
            this.amount = null
            this.transactionRef = payment.AccountTransactionDetails.AccountTransactionDetail[0].ReferenceNumber
            this.paymentInitiated = true
        },
        startPayment() {
            this.transactionRef = null
            this.paymentInitiated = true
        },
        getMembershipPaymentDescription(description) {
            if (description.toString() === 'Mahngebühren 2 Umsatz') return '2. Mahnung'
            if (description.toString() === 'Mahngebühren 3 Umsatz') return '3. Mahnung'
            if (description.toString() === 'Mahngebühren') return '3. Mahnung'
            return description
        },
        getPaymentDescription(description) {
            return description.replace('/Fr. d+(.d{2})?$/', '').trim()
        },
        getChipTypeColor(payment) {
            if (
                payment.Type === 'OPEN_PAYMENT' &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State === 'PAID'
            )
                return 'green'
            if (
                payment.Type === 'OPEN_PAYMENT' &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State !== 'PAID'
            )
                return 'orange'
            if (payment.Type === 'OPEN_PAYMENT' && payment.MainType === 'RESULTING_PAYMENTS') return 'grey'
            if (payment.Type === 'PAYMENT' && payment.MainType === 'PAYMENTS_AND_TRANSACTIONS') return 'green'
            if (
                (payment.Type === 'FEE' || payment.Type === 'IDLE_PERIOD') &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State === 'OPEN'
            )
                return 'red'
            if (
                (payment.Type === 'FEE' || payment.Type === 'IDLE_PERIOD') &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State === 'PAID'
            )
                return 'green'
        },
        getChipTypeText(payment) {
            if (
                payment.Type === 'OPEN_PAYMENT' &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State === 'PAID'
            )
                return 'bezahlt'
            if (
                payment.Type === 'OPEN_PAYMENT' &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State !== 'PAID'
            )
                return 'offen'
            if (payment.Type === 'OPEN_PAYMENT' && payment.MainType === 'RESULTING_PAYMENTS')
                return (
                    'fällig bis: ' +
                    this.$moment(payment.AccountTransactionDetails.AccountTransactionDetail[0].DueDate).format(
                        'DD. MMM YYYY'
                    )
                )
            if (
                (payment.Type === 'FEE' || payment.Type === 'IDLE_PERIOD') &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State === 'OPEN'
            )
                return 'offene Gebühr'
            if (
                (payment.Type === 'FEE' || payment.Type === 'IDLE_PERIOD') &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State === 'PAID'
            )
                return 'bezahlte Gebühr'
            if (payment.Type === 'PAYMENT' && payment.MainType === 'PAYMENTS_AND_TRANSACTIONS') return 'Zahlung'
        },
        getImgForTransactions(type) {
            let transMapper = {
                Mitgliedschaft: '/img/lottiefiles/transactions/typeMembership.png',
                CREDIT: '/img/lottiefiles/transactions/typeCredit.png',
                DEBIT: '/img/lottiefiles/transactions/typePurchase.png',
            }
            return transMapper[type]
        },
    },
    computed: {
        formattedBalance() {
            return formatCurrency(this.payments.balance ? this.payments.balance : 0)
        },

        combinedArray() {
            if (!this.payments.membershipAccountTransactions) this.payments.membershipAccountTransactions = []
            if (!this.payments.secondarySpendAccountTransactions) this.payments.secondarySpendAccountTransactions = []

            return [
                ...this.payments.membershipAccountTransactions
                    .map((item) => ({ ...item, type: 'Mitgliedschaft' }))
                    .filter(
                        (payment) => !(payment.Type === 'PAYMENT' && payment.MainType === 'PAYMENTS_AND_TRANSACTIONS')
                    ),
                ...this.payments.secondarySpendAccountTransactions.map((item) => ({ ...item, type: 'Einkauf' })),
            ]
        },

        transactionsMapped() {
            let transactionsToUse = JSON.parse(JSON.stringify(this.combinedArray))
            if (!this.showAllPayments) {
                transactionsToUse = transactionsToUse.filter((trans) => {
                    return (
                        (trans.Type === 'OPEN_PAYMENT' && trans.MainType === 'RESULTING_PAYMENTS') ||
                        trans.State === 'OPEN'
                    )
                })
            } else {
                transactionsToUse = transactionsToUse.filter((trans) => {
                    return (
                        !(trans.Type === 'OPEN_PAYMENT' && trans.MainType === 'RESULTING_PAYMENTS') &&
                        trans.State !== 'OPEN'
                    )
                })
            }

            return transactionsToUse.sort((a, b) => {
                if (!this.showAllPayments) {
                    return new Date(a.TransactionDate) - new Date(b.TransactionDate)
                }
                return new Date(b.TransactionDate) - new Date(a.TransactionDate)
            })
        },
    },
}
</script>